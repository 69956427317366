import * as React from 'react';
import * as styles from './ComponentSelect.module.scss';

const ComponentSelect = ({activeIndex, selectFunction, data}) => {
    console.log(data);
    const renderItems = () => {
        return data.map((item, index) => {
            return (
                <li
                    className={[styles.item, activeIndex == index ? styles.active : ''].join(' ')}
                    key={index}
                    onClick={() => selectFunction(index)}
                >
                    {item.title}
                </li>
            )
        });
    };

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {renderItems()}
            </ul>
        </div>
    )
};

export default ComponentSelect;