import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import useWindowSize from '../../hooks/window-size';
import PageHeader from '../../components/PageHeader';
import OffsetCardGrid from '../../components/OffsetCardGrid';
import ComponentSelect from '../../components/ComponentSelect';

const FoodPage = ({data}) => {
    const windowSize = useWindowSize();
    const {contentfulBasicPageSection, contentfulPage} = data;
    const [activeContentIndex, setActiveContentIndex] = React.useState(0);

    const selectContent = (index) => {
        setActiveContentIndex(index);
    };

    const productDetails = contentfulPage.elements.filter((productDetailGroup, index) => {
        return productDetailGroup.elements;
    });

    return (
        <Layout>
            <main>
                <PageHeader>
                    <PageTitle>
                        <div>{contentfulBasicPageSection.subtitle}</div>
                        <h1>{contentfulBasicPageSection.title}</h1>
                    </PageTitle>
                    {windowSize.width >= 769 &&
                        <ComponentSelect activeIndex={activeContentIndex} selectFunction={selectContent} data={productDetails}/>
                    }
                </PageHeader>
                <Section hasMaxWidth isFoodPage>
                    <OffsetCardGrid cards={productDetails[activeContentIndex].elements} body={contentfulBasicPageSection.body}>
                        {windowSize.width < 769 &&
                            <ComponentSelect activeIndex={activeContentIndex} selectFunction={selectContent} data={productDetails}/>
                        }
                    </OffsetCardGrid>
                </Section>
            </main>
        </Layout>
    )
}

export default FoodPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulBasicPageSection(
      contentful_id: {eq: "7ct7nhX88vuiBJakbP1AOt"}
      node_locale: {eq: $locale}
    ) {
      ... BasicPageFields
    }
    contentfulPage(contentful_id: {eq: "3McGRFEgtdmZeYyrT9pyFF"}, node_locale: {eq: $locale}) {
      elements {
        ... on ContentfulProductDetailsSection {
          ... ProductDetailsFields
        }
      }
    }
  }
`;
